// Codigos que envia el Backend para indicar el status del usuario en el sistema
// UNRCOA = User Not Registered Casonita Or App (Indica que el usuario no ha sido registrado en el sistema de La Casonita y no tiene solicitudes pendientes de registro)
// UNRC = User Not Registered Casonita (Indica que el usuario no ha sido registrado en el sistema de La Casonita y tiene solicitudes pendientes de registro)
// UNROA = User Not Registered On App (Indica que el usuario se encuentra registrado en el sistema de La Casonita pero no ha sido dado de alta en la App)
// URCAA = User Registered Casonita And App (Indica que el usuario se encuentra registrado en el sistema de La Casonita y también fue dado de alta en la App)

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDocumentId } from '../utils/formatDocumentId';
import { formatPhoneNumber } from '../utils/formatPhoneNumber';
import { NavController } from '@ionic/angular';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // url = 'https://oficinavirtual.productospya.com';
  url = environment.apiUrl;
  documentId: number = null;
  profileType: string = null;

  constructor(private http: HttpClient, private navCtrl: NavController) {
    // const parsedUrl = new URL(window.location.href);
    // const baseUrl = parsedUrl.origin + ':5002';
    // this.url = baseUrl;
  }

  // login
  auth(user: any){
    const data = {
      documentId: formatDocumentId(user.documentId),
      profileType: Number(user.profileType),
      password: user.password
    };
    return this.http.post(this.url + '/api/v1/Auth', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  logOut () {
    localStorage.clear();
    this.navCtrl.navigateRoot('/');
  }

  validateUser(documentId, profileType) {
    return this.http.get(this.url + `/api/v1/Users/${documentId}/?profileType=${profileType}`, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  // recuperar contrasena
  recoverPass(data){
    const userData = {
      documentId: formatDocumentId(data.documentId),
      profileType: Number(data.profileType),
      notificationType: Number(data.notificationType),
    };
    return this.http.get(
      `${this.url}/api/v1/Auth/${userData.documentId}/RecoverPass?profileType=${userData.profileType}&notificationType=${userData.notificationType}`
    );
  }

  refreshToken(token: any){
    return this.http.post(this.url + '/api/v1/Auth/refresh', token, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  // obtener lista de servicios que van en pantalla de REGISTRO (red profesional de trabajo) 
  getServices() {
    return this.http.get(this.url + `/api/v1/Services`);
  }

  registerProfessional(professional) {
    // formateamos data
    const data = {
      documentId: formatDocumentId(professional.documentId),
      serviceCode: Number(professional.serviceCode),
      firstName: professional.firstName,
      lastName: professional.lastName,
      email: professional.email,
      phoneNumber: professional.phoneNumber
    };

    return this.http.post(this.url + '/api/v1/Users', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getDocumentId() {
    return parseInt(localStorage.getItem('documentId'));
  }

  setDocumentId(documentId) {
    localStorage.setItem('documentId', documentId)
  }

  getProfileType() {
    return localStorage.getItem('profileType');
  }

  setProfileType(profileType) {
    localStorage.setItem('profileType', profileType);
  }

  getJWT() {
    return JSON.parse(localStorage.getItem('jwt'));
  }

  setJWT(jwt) {
    localStorage.setItem('jwt', JSON.stringify(jwt));
  }
}

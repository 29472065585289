import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/cedula/cedula.module').then( m => m.CedulaPageModule)
  },
  {
    path: 'profileType/:profile',
    loadChildren: () => import('./pages/cedula/cedula.module').then( m => m.CedulaPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    // Las rutas que permiten al usuario navegar en la app autenticado se encuentran en el page SPLIT-LAYOUT
    path: 'app',
    loadChildren: () => import('./pages/split-layout/split-layout.module').then( m => m.SplitLayoutPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    // TUTORIAL PARA IMPLEMENTAR EL INTERCEPTOR Y REFRESH https://dev-academy.com/angular-jwt/#refresh-token
    jwt: any;
    isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authSvc: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let interceptedRequest = request;
        this.jwt = this.authSvc.getJWT();

        if (this.jwt !== null) {
            interceptedRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.jwt.token) });
        }
        return next.handle(interceptedRequest).pipe(catchError(error => {
            if (error.status === 401) {
              return this.handle401Error(interceptedRequest, next);
            } else if (error.status === 0) { // errores con status 0 son debido a CORS
              throw {error: 'Ha ocurrido un error de conexión. Intentelo nuevamente.'};
            } else {
              return throwError(error);
            }
          }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);
      
          return this.authSvc.refreshToken(this.jwt).pipe(
            switchMap((token: any) => {
              this.jwt = token;
              this.authSvc.setJWT(token);
              this.isRefreshing = false;
              this.refreshTokenSubject.next(token.token);
              const req = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token.token) });
              return next.handle(req);
            }));
      
        } else {
          return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(jwt => {
                this.jwt = jwt;
                this.authSvc.setJWT(jwt);
                const req = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + jwt.token) });
                return next.handle(req);
            }));
        }
      }
}

export const interceptorProvider = [{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}];
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-error-profile',
  templateUrl: './modal-error-profile.component.html',
  styleUrls: ['./modal-error-profile.component.scss'],
})
export class ModalErrorProfileComponent implements OnInit {

  @Input() isHidden: boolean;
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  closeModal() {
    this.close.emit(false);
  }


}

import { Component, OnInit } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-menu-afiliado',
  templateUrl: './menu-afiliado.component.html',
  styleUrls: ['./menu-afiliado.component.scss'],
})
export class MenuAfiliadoComponent implements OnInit {

  type = 'overlay';

  constructor( private menuCtrl: MenuController,
    private platform: Platform ) { }

  ngOnInit() {
    if ( this.platform.is( 'desktop' ) ){
      this.type = 'reveal';
    }
  }

  
  closeMenu(){
    this.menuCtrl.close( 'menuAfiliado' );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { PopoverComponent } from './popover/popover.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalErrorProfileComponent } from './modal-error-profile/modal-error-profile.component';
import { MenuAfiliadoComponent } from './menu-afiliado/menu-afiliado.component';

@NgModule({
  declarations: [
    MenuComponent,
    HeaderComponent,
    PopoverComponent,
    ModalErrorComponent,
    ModalErrorProfileComponent,
    MenuAfiliadoComponent
  ],
  exports: [
    MenuComponent,
    HeaderComponent,
    PopoverComponent,
    ModalErrorComponent,
    ModalErrorProfileComponent,
    MenuAfiliadoComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
  ]
})
export class ComponentsModule { }

import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { ProfessionalService } from 'src/app/services/professional.service';
import { formatDocumentId } from 'src/app/utils/formatDocumentId';
import { parseJwt } from '../../utils/parseJwt';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  isDesktop = false;
  modal = false;
  user: string;
  role: string;
  imageToShow: any;

  @Input() titulo: string = '';

  constructor(
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private platform: Platform,
    private authSvc: AuthService,
    private professionalSvc: ProfessionalService,
    public loadingController: LoadingController
  ) { }

  ngOnInit() {
    if ( this.platform.is('desktop') ){
      this.isDesktop = true;
    }
    this.setUser();
    if (this.authSvc.getProfileType() === 'Profesional de la Salud') {
      this.role = 'profesional';
      this.getProfessionalImage();
    } else {
      this.role = 'afiliado';
    }
    // esto se ejecuta solo cuando el profesional actualiza su foto de perfil
    const subscription = this.professionalSvc.updateHeader$.subscribe(
      value => {
        this.getProfessionalImage();
    });
  }

  async getProfessionalImage() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Cargando...',
    });
    
    try {
      loading.present();
      const data = await this.professionalSvc.getImageProfile(
        formatDocumentId(this.authSvc.getDocumentId())
      ).toPromise();
      // no tiene foto asignada aun
      if (data.status === 204) {
        const data = await fetch('../../../../../assets/img/avatar.png');
        const avatar = await data.blob();
        this.createImageFromBlob(avatar);
      } else {
        this.createImageFromBlob(data.body);
      }
      loading.dismiss();
    } catch (error) {
      console.log(error);
      loading.dismiss();
      const data = await fetch('../../../../../assets/img/avatar.png');
      const avatar = await data.blob();
      this.createImageFromBlob(avatar);
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }

  openMenu() {
    this.menuCtrl.open();
  }

  toggleModal(){
    this.modal = !this.modal;
  }

  logOut() {
    this.modal = !this.modal;
    if (this.role === 'afiliado') {
      localStorage.clear();
      this.navCtrl.navigateRoot('profileType/afiliado');
    } else if (this.role === 'profesional') {
      localStorage.clear();
      this.navCtrl.navigateRoot('profileType/profesional');
    } else {
      this.authSvc.logOut();
    }
  }

  setUser() {
    const jwt = this.authSvc.getJWT();
    const token = parseJwt(jwt.token);
    this.user = token.name;
  }

}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent implements OnInit {

  @Input() isHidden = false;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() titulo: string;
  @Input() mensaje: string;

  constructor() { }

  ngOnInit() {}

  closeLoginModal() {
    this.close.emit(false);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDocumentId } from '../utils/formatDocumentId';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {

  // url = 'https://oficinavirtual.productospya.com';
  url = environment.apiUrl;
  serviceCode: number; // codigo de servicio del profesional. Dice en que area trabaja
  atentionOrderCode: any; // codigo de orden de atencion
  cleanSiguiente: boolean; // codigo de orden de atencion
  atentionOrderData: any; // datos de orden de atencion
  medicalHistoryForm: any; // formulario de 2da pantalla de atencion medica
  medicalcalHistoryData: any; // data que posee las respuestas al formulario y que debe ser enviado en Procedimientos
  areQuestionsToUpdate: boolean; // variable que informara si la historia medica es para editar o crear
  firstTime: boolean; // variable que informara si es primera vez con el profeisonal
  firstTimeMedicalHistory: boolean; // variable que informara si es primera vez pasando por la historia medica
  userData: any; // variable que contiene el nombre y cedula del paciente y fecha de nacimiento para mostrar en pantallas de orden de atencion

  private updateHeader = new Subject<boolean>();
  updateHeader$ = this.updateHeader.asObservable();

  constructor(private http: HttpClient) {
    // const parsedUrl = new URL(window.location.href);
    // const baseUrl = parsedUrl.origin + ':5002';
    // this.url = baseUrl;
  }

  emitToHeader() {
    this.updateHeader.next(true);
  }

  getServiceCode() { return this.serviceCode; }
  setServiceCode(code: string) { this.serviceCode = Number(code); }

  getmedicalHistoryForm() { return this.medicalHistoryForm; }
  setmedicalHistoryForm(form: any) { this.medicalHistoryForm = form; }

  getAtentionOrderData() { return this.atentionOrderData; }
  setAtentionOrderData(data: any) { this.atentionOrderData = data; }

  getAtentionOrderCode() { return this.atentionOrderCode; }
  setAtentionOrderCode(code: any) { this.atentionOrderCode = code; this.cleanSiguiente = true }

  getClenSiguiente() { return this.cleanSiguiente; }

  getMedicalHistoryData() { return this.medicalcalHistoryData; }
  setMedicalHistoryData(data: any) { this.medicalcalHistoryData = data; }

  getFirstTimeMedicalHistory() { return this.firstTimeMedicalHistory; }
  setFirstTimeMedicalHistory(data: any) { this.firstTimeMedicalHistory = data; }

  getAreQuestionsToUpdate() { return this.areQuestionsToUpdate; }
  setAreQuestionsToUpdate(data: any) { this.areQuestionsToUpdate = data; }

  getFirstTime() { return this.firstTime; }
  setFirstTime(data: any) { this.firstTime = data; }

  getUserData() { return this.userData; }
  setUserData(data: any) { this.userData = data; }

  // ficha de usuario
  userInfo(documentId: string){
    return this.http.get(this.url + `/api/v1/Professionals/${(documentId)}/userInfo`);
  }

  // editar ficha de usuario
  editProfessional(userData) {
    return this.http.post(this.url + `/api/v1/Professionals/${userData.documentId}/update`, userData);
  }

  // ficha profesional
  userProfessional(documentId: string){
    return this.http.get(this.url + `/api/v1/Professionals/${documentId}`);
  }

  // endpoint para obtener SOLO los centros de atencion de un profesional
  professionalCentralCares(documentId: string) {
    return this.http.get<any[]>(this.url + `/api/v1/Professionals/${documentId}/centralCares`);
  }

  // endpoint para obtener SOLO los centros de atencion de un profesional habilitados e inhabilitados
  allProfessionalCentralCares(documentId: string) {
    return this.http.get<any[]>(this.url + `/api/v1/Professionals/${documentId}/centralCares?active=false`);
  }

  // obtener los horarios de los centros de atencion
  getSchedules() {
    return this.http.get(this.url + `/api/v1/Generals/schedules`);
  }

  // endpoint para obtener la informacion de un centro de atencion de un profesional
  centralCareInformation(documentId: string, centralCareCode: string) {
    return this.http.get(this.url + `/api/v1/Professionals/${documentId}/centralCares/${centralCareCode}/consultingRoom`);
  }

  // editar informacion del centro de atencion
  updateCentralCareInformation(documentId: string, centralCareCode: string, data: any) {
    return this.http.post(
      this.url + `/api/v1/Professionals/${documentId}/centralCares/${centralCareCode}/consultingRoom/update`,
      data
    );
  }

  // modificacion de contraseña
  updatePassword(documentId: string, profileType: number, password: string, oldPassword) {
    const data = { documentId, password, oldPassword, profileType };
    return this.http.post(this.url + `/api/v1/Users/${documentId}/password/update`, data);
  }

  // obtener imagen de profesional
  getImageProfile(documentId: string) {
    return this.http.get(this.url + `/api/v1/Professionals/${documentId}/imageProfile`, { responseType: 'blob', observe: 'response' });
  }

  // modificar imagen de profesional
  updateImageProfile(documentId: string, file: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/imageProfile/update`, file);
  }

  // obtener pagos para modulo de pagos
  getPayments(documentId: string, initialDate: string, finalDate: string, page: number, centralCareId: string) {
    return this.http.get<any[]>(
      this.url + 
      `/api/v1/Professionals/${documentId}/payments?initialDate=${initialDate}&finalDate=${finalDate}&page=${page}&centralCareId=${centralCareId}`
    );
  }

  // se utiliza en finantialData en caso de que el profesional reciba los pagos a los centros de atencion
  getCentralCaresData(documentId: string, centralCareCodes: any[]){
    return Promise.all(centralCareCodes.map(centralCare => this.http.get(this.url + `/api/v1/Professionals/${documentId}/centralCares/${centralCare.code}/consultingRoom`).toPromise()));
  }

  // conjunto de datos que se deben tener al cargar modulo de datos financieros
  getFinantialData(documentId: string) {
    return Promise.all([
      // this.http.get(this.url + `/api/v1/Professionals/${documentId}/finantialData`).toPromise(),
      this.http.get(this.url + `/api/v1/Accounts`).toPromise(),
      this.http.get(this.url + `/api/v1/Banks`).toPromise(),
      this.http.get(this.url + `/api/v1/Professionals/${documentId}/centralCares`).toPromise(),
    ]);
  }

  // se utiliza en datos financieros para obtener la data financiera del profesional
  getProfessionalFinantialData(documentId: string) {
    return this.http.get(this.url + `/api/v1/Professionals/${documentId}/finantialData`)
  }

  // se utiliza en datos financieros para obtener la data financiera de los centros de atencion
  getCentralCaresFinantialData(centralCareCode: any){
    return this.http.get(this.url + `/api/v1/CentralCares/${centralCareCode}/finantialData`);
  }

  // modificar los datos financieros de un profesional
  updateFinantialData(documentId: string, body: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/finantialData/update`, body);
  }

  // obtener la imagen del cheque en modulo de datos financieros
  getCheckImage(documentId: string) {
    return this.http.get(this.url + `/api/v1/Professionals/${documentId}/finantialDataPhoto`, { responseType: 'blob', observe: 'response' });
  }

  // modifica la imagen del cheque en modulo de datos financieros
  updateCheckImage(documentId: string, file: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/finantialDataPhoto/update`, file);
  }

  // datos de orden de atencion
  getAttentionOrder(atentionNumber: string) {
    return  this.http.get(this.url + `/api/v1/AtentionOrders/${atentionNumber}`);
  }

  // dvalida los atos de la orden de atencion
  validateAttentionOrder(documentId: string, atentionNumber: string, serviceCode: string, centralCareId: string) {
    return  this.http.get(this.url + `/api/v1/Professionals/${documentId}/atentionOrders/${atentionNumber}/validate?serviceCode=${serviceCode}&centralCareId=${centralCareId}`);
  }
  
  // tabla de historia medica
  getMedicalHistory(documentId: string, customerId: string, affiliatedCompany: string, empresaContratante: string) {
    return this.http.get(this.url + 
      `/api/v1/Professionals/${documentId}/treatments?customerId=${customerId}&affiliatedCompanyId=${affiliatedCompany}&esChevere=${empresaContratante === 'J404854185' ? 'true' : 'false'}`
    );
  }

  // preguntas a cargar en formulario de historia medica
  getQuestionsMedicalHistory(serviceCode: number) {
    return this.http.get(this.url + `/api/v1/Services/${serviceCode}/questions`);
  }

  // registra las preguntas de historia medica
  registerQuestions(documentId: string, data: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/requestOrderQuestions`, data);
  }

  // modifica las preguntas de hiostoria medica
  updateQuestions(documentId: string, data: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/requestOrderQuestions/update`, data);
  }

  // al entrar en la pantalla de historia medica puede pasar que el paciente y doctor ya hayan tenido citas previas
  // por esta razon debemos chequear si hay historia medica y en caso de haberla cargarla en el formulario
  getDataQuestionsMedicalHistory(professionalId: any, atentionNumber: any) {
    return this.http.get(this.url + `/api/v1/AtentionOrders/${atentionNumber}/complete?profesionalId=${professionalId}`);
  }

  getCompleteAtentionOrderData(atentionNumber: string, professionalId: any) {
    return this.http.get(this.url + `/api/v1/AtentionOrders/${atentionNumber}/complete?profesionalId=${professionalId}`);
  }

  // para crear un tratamiento en la ultima pantalla de atencion medica
  createTreatment(documentId: string, data: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/requestOrderTreatment`, data);
  }

  // para eliminar un tratamiento en la ultima pantalla de atencion medica
  deleteTreatment(documentId: string, serviceId: string, centralCareId: string, serviceKeyId: string, data: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/requestOrderTreatment/delete?serviceId=${serviceId}&centralCareId=${centralCareId}&serviceKeyId=${serviceKeyId}`, data);
  }

  // lista de procedimientos para pantalla 3 (procedimientos o tratamientos anteriormente) de atencion medica
  getProcedimientosList(serviceCode: number, date: string, professionalId: string, birthDay: string) {
    return this.http.get(this.url + `/api/v1/Treatments?serviceCode=${serviceCode}&date=${date}&professionalId=${professionalId}&birthDay=${birthDay}`);
  }

  // servicio para la subida de imagenes de tratamiento
  uploadImageTreatment(profesionalId: string, serviceKey: string, centralCareId: string, serviceId: string, dateTime: string, number: number, nombre: string, data: any) {
    return this.http.post(this.url + `/api/v1/Professionals/${profesionalId}/atentionOrders/${serviceKey}/uploadImageTreatment?centralCareId=${centralCareId}&serviceId=${serviceId}&dateTime=${dateTime}&number=${number}&nombre=${nombre}`, data);
  }

  // obtener ordenes de atencion para modulo de reportes
  getOrdenesAtencion(documentId: string, centralCareCode: string, esChevere: number) {
    return this.http.get<any[]>(
      this.url + 
      `/api/v1/Professionals/${documentId}/centralCares/${centralCareCode}/atentionOrders?esChevere=${esChevere}`
    );
  }

  // valida los reportes del profesional
  validateReport(profesionalId: string, centralCareId: string, esChevere: number) {
    return this.http.get(this.url + `/api/v1/Professionals/${profesionalId}/reportBatch/validate?centralCareId=${centralCareId}&esChevere=${esChevere}`)
  }

  // Elimina la orden de atención para modulo de reportes
  deleteOrdenAtencion(atentionNumber: string, documentId: string, centralCareId: string, body: any) {
    return this.http.post(
      this.url + 
      `/api/v1/AtentionOrders/${atentionNumber}/delete?profesionalId=${documentId}&centralCareId=${centralCareId}`, 
      body
    );
  }

  // cargar reportes del modulo de reportes
  uploadReport(profesionalId: string, centralCareId: string, esChevere: number) {
    return this.http.get(this.url + `/api/v1/Professionals/${profesionalId}/reportBatch?centralCareId=${centralCareId}&esChevere=${esChevere}`)
  }

  // solicitar modificacion de datos del profesional
  requestModification(documentId: string, text: string) {
    return this.http.post(this.url + `/api/v1/Professionals/${documentId}/requestModification`, {text: text})
  }

  // envio de sugerencias
  sendSuggestion(documentId: string, typeUser: number, text: string) {
    return this.http.post(this.url + `/api/v1/Generals/send-suggestion`, {
      typeUser, text, documentId
    })
  }

}

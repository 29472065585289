/** Esta funcion esta encargada de formatear la Cedula de Identidad
 *  para el registro en la BBDD de Grupo Casonita. El formato de
 *  una Cedula de Identidad debe ser de 10 caracteres, con ceros
 *  delante del valor original. Ejemplo
 *  Para la cedula 12.345.678 el valor retornado debe ser 0012345678
 */

export const formatDocumentId = (documentId: number) => {
    const id = String(documentId);
    
    if (id.length >= 11) {
        return id;
    }

    const cantCeros = 11 - id.length;
    let formattedDocumentId = '';

    for (let i = 0; i < cantCeros; i++) {
        formattedDocumentId += '0';
    }
    formattedDocumentId += documentId;
    return formattedDocumentId;
};

import { Component, OnInit } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  type = 'overlay';

  constructor( private menuCtrl: MenuController,
    private platform: Platform ) { }

  ngOnInit() {
    if ( this.platform.is( 'desktop' ) ){
      this.type = 'reveal';
    }
  }

  closeMenu(){
    this.menuCtrl.close( 'menuProfesional' );
  }

}
